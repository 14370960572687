import { Controller } from 'stimulus';
import Swiper, {EffectFade, Pagination} from 'swiper';

Swiper.use([EffectFade, Pagination]);

export default class extends Controller {
  connect() {
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);
    this.initSlider();
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize);
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  /**
   * Initialize the slider -
   * This will initialize the slider based on the window width,
   * and will destroy the slider if it already exists.
   * This is necessary because the slider needs to be re-initialized in order to change the effect,
   * which is different on mobile and tablet.
   * We can't just use the `breakpoints` array in the Swiper config,
   * since at this time, Swiper doesn't support breakpoints configuration for effects.
   * @returns {void}
   */
  initSlider() {
    // get the window width
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // if the window width is less than 768px, initialize the fade slider
    if (windowWidth < 768) {
      this.initFadeSwiper();
    } else if (windowWidth < 1024) {
      // if the window width is less than 1024px, initialize the slide slider
      this.initSlideSwiper();
    } else {
      // if the window width is greater than 1024px, destroy the slider
      if (this.swiper) {
        this.swiper.destroy(true, true);
        this.swiper = null;
      }
    }
  }

  /**
   * Handle the window resize event -
   * This will destroy the slider if it exists, and then re-initialize it.
   * @returns {void}
   */
  handleResize() {
    // if the slider exists, destroy it
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
    // re-initialize the slider
    this.initSlider();
  }

  /**
   * Initialize the fade slider -
   * This will initialize the slider with the fade effect.
   * @returns {void}
   */
  initFadeSwiper() {
    // initialize the slider,
    // intended for mobile designs
    this.swiper = new Swiper(this.element.querySelector('.js-relatedStoriesSlider'), {
      slidesPerView: 1,
      speed: 400,
      threshold: 35,
      resistance: true,
      resistanceRatio: 0,
      slidesPerGroup: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  /**
   * Initialize the slide slider -
   * This will initialize the slider with the slide effect.
   * @returns {void}
   */
  initSlideSwiper() {
    // initialize the slider,
    // intended for tablet designs
    this.swiper = new Swiper(this.element.querySelector('.js-relatedStoriesSlider'), {
      slidesPerView: 2,
      speed: 400,
      threshold: 76,
      resistance: true,
      resistanceRatio: 0,
      slidesPerGroup: 1,
      spaceBetween: 12,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}
